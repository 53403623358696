<template>
	<div class="app-container"
		:style="{direction: yuyan.now=='hanyu'?'ltr':'rtl',textAlign: yuyan.now=='hanyu'? 'left':'right', margin: '20px'}">

		<el-card style="margin-top: 20px">

			<div class="qu-content">

				<p style="font-size: 18px;">【{{ quData.quType | quTypeFilter }}】{{ quData.content }}</p>
				<div v-if="images.length!==0">
					<el-image v-for="item in images" style="height: 300px;width: auto;" :preview-src-list="images"
						:src="item" fit="contain"></el-image>
				</div>
				<div v-if="quData.quType === 1 || quData.quType===3 ">
					<el-radio-group v-model="answerValues[0]" readonly>
						<el-radio v-for="an in quData.answerList" :label="an.id" readonly style="margin-right: 10px;">{{ an.abc }}.{{ an.content }}
							<div v-if="an.image!==''" style="display: flex;flex-wrap: wrap;">
								<el-image :preview-src-list="[imgShow(an.image)]" style="height: 150px;width: auto;"
									:src="imgShow(an.image)" fit="contain">
								</el-image>
							</div>
						</el-radio>
					</el-radio-group>
				</div>

				<!-- 多选题 -->
				<div v-if="quData.quType === 2">
					<el-checkbox-group v-model="answerValues" readonly>
						<el-checkbox v-for="an in quData.answerList" :label="an.id">{{ an.abc }}.{{ an.content }}
							<div>
								<el-image :preview-src-list="[imgShow(an.image)]" style="height: 150px;width: auto;"
									:src="imgShow(an.image)" fit="contain">
								</el-image>
							</div>
						</el-checkbox>
					</el-checkbox-group>
				</div>

				<div v-if="analysisShow" style="margin-top: 20px; color: #1890ff; font-weight: bold">
					{{yuyan.jiaojuan_yes}}：{{ rightTags.join(' ') }}
				</div>

			</div>

		</el-card>

		<el-card v-if="analysisShow" class="qu-analysis" style="margin-top: 20px">
			{{yuyan.ViewQu_jiexi}}：
			<p>{{ quData.analysis }}</p>
			<p v-if="!quData.analysis">{{yuyan.ViewQu_no_jiexi}}！</p>
		</el-card>

		<el-card v-if="analysisShow" class="qu-analysis" style="margin-top: 20px;">
			{{yuyan.practice_choose}}：
			<div v-for="an in quData.answerList" class="qu-analysis-line">
				<div v-if="an.analysis">
					<p style="color: #555;">{{ an.content }}：</p>
					<p style="color: #1890ff;">{{ an.analysis }}</p>
				</div>
			</div>
			<p v-if="analysisCount === 0">{{yuyan.ViewQu_no_choosejiexi}}</p>

		</el-card>

		<div style="padding-top: 30px">
			<el-button type="primary" @click="handNext">{{yuyan.practice_next}}</el-button>
			<el-button style="margin-right: 10px;" type="info" @click="onCancel">{{yuyan.fanhui}}</el-button>
		</div>

	</div>
</template>

<script>
	import {
		fetchDetail,
		nextQu
	} from '@/api/lianxi'

	export default {
		name: 'BookTrain',
		data() {
			return {
				images: [],
				yuyan: {},
				examId: '',
				quId: '',
				tags: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N'],
				analysisShow: false,
				quData: {

				},
				answerValues: [],
				rightValues: [],
				rightTags: []

			}
		},
		watch: {
			'$store.state.language_num'(to, from) {
				this.yuyan = this.$language()
			}
		},
		created() {
			this.yuyan = this.$language()
			this.examId = this.$route.params.examId

			this.fetchNextQu()
		},
		methods: {
			imgShow(url) {
				var r = new RegExp(/http/g); //构造正则表达式对象
				if (r.test(url))
					return url
				else
					return 'https://admin.rolumapp.com/upload/' + url
			},
			// 清理值
			clearValues() {
				this.answerValues = []
				this.rightValues = []
				this.analysisShow = false
				this.rightTags = []
			},

			// 查找试卷详情
			fetchQuDetail(id) {
				// 当前赋值
				this.quId = id
				this.clearValues()

				fetchDetail(id).then(response => {
					// 题目信息
					this.quData = response
					if (response.images) {
						this.images = response.images.split("%")
						this.images.forEach((item, index) => {
							var r = new RegExp(/http/g); //构造正则表达式对象
							if (r.test(item)) {} else {
								this.images[index] = 'https://admin.rolumapp.com/upload/' + item
							}
						})
					}
					// 保存正确答案
					this.quData.answerList.forEach((an, index) => {
						an.abc = this.tags[index]

						// 用户选定的
						if (an.isRight) {
							this.rightValues.push(an.id)
							this.rightTags.push(an.abc)
						}
					})
				})
			},

			fetchNextQu() {
				// 查找下一个
				nextQu(this.examId, this.quId).then(response => {
					this.fetchQuDetail(response.id)
				})
			},
			onCancel() {
				this.$router.go(-1)
			},

			handNext() {
				// 直接显示下一个
				if (this.analysisShow) {
					// 正确显示下一个
					this.fetchNextQu()
				} else {
					// 直接判断正确性
					if (this.rightValues.join(',') === this.answerValues.join(',')) {
						this.$message({
							message: '回答正确，你好棒哦！',
							type: 'success'
						})

						// 正确显示下一个
						this.fetchNextQu()
					} else {
						// 错误显示解析
						this.analysisShow = true

						this.$message({
							message: '很遗憾，又做错了呢，请参考答案解析！',
							type: 'error'
						})
					}
				}
			}

		}
	}
</script>

<style scoped>
	.qu-content div {
		line-height: 50px;
	}

	.qu-analysis p {
		color: #555;
		font-size: 14px
	}

	.qu-analysis-line {
		margin-top: 20px;
		border-bottom: #eee 1px solid
	}

	.el-checkbox-group label,
	.el-radio-group label {
		width: 100%;
	}
</style>
